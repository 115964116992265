.warning-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 3em;
  z-index: 10;
  opacity: 0.5;
}

.battery-warning-overlay {
  position: fixed;
  bottom: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 3em;
  z-index: 10;
  opacity: 0.5;
}