.ptz-ctl-box {
    position: relative;
    background-color: #000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ptz-pad-box {
    width: 160px;
    height: 160px;
    border-radius: 80px;
    background-color: #ddd; /* Softer gray */
    color: #000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ptz-goto-home {
    width: 50px;
    height: 50px;
    border-width: 0;
    border-radius: 25px;
    background-color: #ddd; /* Softer gray */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
}

.ptz-goto-home:hover {
    background-color: #bbb; /* Darker gray on hover */
}

.ptz-goto-home:active {
    background-color: #999; /* Even darker gray on active */
    box-shadow: inset 0 0 5px #333; /* Add a shadow effect to show pressing */
}

.ptz-pad-box .fa-home {
    font-size: 24px;
}

.ptz-pad-box .fa-arrow-left,
.ptz-pad-box .fa-arrow-right,
.ptz-pad-box .fa-arrow-up,
.ptz-pad-box .fa-arrow-down {
    position: absolute;
    font-size: 24px;
    cursor: pointer;
    color: #333; /* Softer arrow color */
    transition: all 0.2s ease;
}

.ptz-pad-box .fa-arrow-left:hover,
.ptz-pad-box .fa-arrow-right:hover,
.ptz-pad-box .fa-arrow-up:hover,
.ptz-pad-box .fa-arrow-down:hover {
    color: #000; /* Darker arrow color on hover */
}

.ptz-pad-box .fa-arrow-left:active {
    color: #666; /* Different color on active */
    transform: scale(0.8) translateY(-50%);
}

.ptz-pad-box .fa-arrow-right:active {
    color: #666; /* Different color on active */
    transform: scale(0.8) translateY(-50%);
}

.ptz-pad-box .fa-arrow-up:active {
    color: #666; /* Different color on active */
    transform: scale(0.8) translateX(-50%);
}

.ptz-pad-box .fa-arrow-down:active {
    color: #666; /* Different color on active */
    transform: scale(0.8) translateX(-50%);
}

.ptz-pad-box .fa-arrow-left {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: left center;
}

.ptz-pad-box .fa-arrow-right {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: right center;
}

.ptz-pad-box .fa-arrow-up {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: center top;
}

.ptz-pad-box .fa-arrow-down {
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: center bottom;
}

.ptz-spd-ctl-box {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0.3em;
    padding: 0.5em;
    margin-top: 10px;
}

.ptz-spd-ctl-box .label {
    display: block;
    margin-bottom: 0.3em;
}

.ptz-zom-ctl-box {
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin-top: 10px;
}

.ptz-zom-ctl-box .ptz-zom {
    width: 50px;
    height: 50px;
    border-width: 0;
    background-color: #ddd; /* Softer gray */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
}

.ptz-zom-ctl-box .ptz-zom:hover {
    background-color: #bbb; /* Darker gray on hover */
}

.ptz-zom-ctl-box .ptz-zom:active {
    background-color: #999; /* Even darker gray on active */
    transform: scale(0.9);
}

.disconnect-box {
    margin-top: 10px;
}

.disconnect-box button {
    background-color: #ddd; /* Softer gray */
    border-width: 0;
    color: #333; /* Softer text color */
    opacity: 0.8;
    transition: all 0.2s ease;
}

.disconnect-box button:hover {
    background-color: #bbb; /* Darker gray on hover */
    color: #000; /* Darker text color on hover */
    opacity: 1.0;
}

.disconnect-box button:active {
    background-color: #999; /* Even darker gray on active */
    color: #666; /* Different text color on active */
}
