
.accordion .accordion-item{
    border: none;
    margin-bottom: 30px;
    background: none;
} 


.btn-transparent {
    background-color: transparent;
    border-color: transparent;
    color: white;
}

.waypoint:hover {
    cursor: pointer;
}

.text-disabled {
    color : #E1E1E1 !important;
}

.text-dark {
    color : #2D2D2D;
}

/* .offcanvas {
    transition: transform .3s ease-in-out !important;
} */

.path-planning-map-container {
    height : 300px;
    width: 100%;
}


/* Modal */
.path-planning-modal .modal-content {
    height : 100%;
    overflow-y: hidden;
}

.path-planning-modal .modal-dialog {
    height : 94%;
    max-height: 94vh;
    margin : 2.5vh auto;
    overflow-y: initial !important;
}

.path-planning-modal .modal-body {
    height : 95vh;
    overflow-y: hidden;
}

#path-planning-map {
    max-height : 93.8vh;
}

.path-planning-column {
    max-height : 94vh;
    
}

.waypoint-form-body {
    min-height: 250px;
    max-height: 250px;
}

.waypoint-disabled {
    color: #6c757d;
    border-color: transparent;
    opacity: 0.65;
}

.scheduler-body {
    min-height: 250px;
    max-height: 80vh;
}

.mission-status-updates {
    height : 75px;
    width : 100px;
    border-radius: 0.375rem;
}

#mission-progress .bg-success {
    background-color: #28A745 !important;
}


@media only screen and (max-width: 991.98px) {
    .path-planning-modal .modal-dialog,
    .path-planning-modal .modal-content{
        height : 100%;
        
    }
}

.modal-90w {
    width: 80% !important;
    max-width: none !important;
    
}

.modal-90w .modal-body {
    background: #F1F2F4 !important;
    
}

.mission-plan:hover {
    cursor: pointer;
}

.add-button:hover{
    cursor: pointer;
}

/* .mission-plan-card{
    width : 138px;
    height : 150px;
    border : 2px solid #0D6EFD !important;
    border-radius: .9rem !important;
}

.mission-plan-card-placeholder {
    width : 138px;
    height : 150px;
    border : 2px dashed #0D6EFD !important;
    border-radius: .9rem !important;
    text-align: center;
}

.mission-plan-card:hover{
    cursor: pointer;
} */

.custom-select-dropdown {
    border-bottom: 2px solid #0D6EFD;
    /* border-radius: 0.8rem; */
}

.custom-select-dropdown:hover{
    cursor: pointer;
}

.mission-planning-menu {
    transform: translate(15px, 45px) !important;
}

.mission-plan-card {
    margin-bottom: 10px;
    border: none !important;
    background: transparent !important;
    border-radius: none !important;
}

.mission-plan-card .card-body { 
    background: white;
}

.mission-plan-card-header {
    background-color: transparent !important;
    border: none !important;
    border-radius: none !important;
}

.mission-plan-card-toggle {
    color : #2D2D2D !important;
    text-decoration: none !important;
    letter-spacing: 0;
    font-size: 18px;
    background: white !important;
    border-radius: 5px !important;
    /* box-shadow: 2px 2px 5px rgba(0, 0, 0, .19); */
    /* border: 1px solid rgba(0, 0, 0, .19) !important; */
    width: 100%;
    height: 100%;
}

.mission-plan-card-toggle:hover {
    text-decoration: none !important;
    outline: none !important;
    /* -webkit-box-shadow : 2px 2px 5px rgba(0, 0, 0, .19) !important;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .19) !important; */
}

.mission-plan-card-toggle:focus {
    outline : none !important;
    box-shadow: none !important;
}







