.ctop {
    position: absolute;
    color: white;
    background-color: rgba(255, 0, 0, 0.7);
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    font-size: 3em;
    z-index: 10;
    opacity: 0.5;
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
}

.cbottom {
    position: absolute;
    color: white;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(255, 94, 1);
    text-align: center;
    font-size: 3em;
    z-index: 10;
    opacity: 0.5;
    bottom: 10vh;
    left: 50%;
    transform: translateX(-50%);
}

.cleft {
    position: absolute;
    color: white;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(255, 94, 1);
    text-align: center;
    font-size: 3em;
    z-index: 10;
    opacity: 0.5;
    top: 50%;
    left: 10vw;
    transform: translateY(-50%);
}

.cright {
    position: absolute;
    color: white;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(255, 94, 1);
    text-align: center;
    font-size: 3em;
    z-index: 10;
    opacity: 0.5;
    top: 50%;
    right: 10vw;
    transform: translateY(-50%);
}
