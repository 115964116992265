/**
 * CSS file for all the progress bars involved in the project
 */

 .circle-progress {
    display: block;
    margin: 20px auto;
    max-width: 100%;
}

/* .circle-progress{ 
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
} */

.circle-progress .svg-circle-progress {
    transition: stroke-dashoffset 1s linear;
}

.svg-circle-progress-bg {
    fill: none;
}

.svg-circle-progress {
    fill: none;
}
.svg-circle-progress-text {
   font-size: 2rem;
    text-anchor: middle;
    fill: #fff;
    font-weight: bold;
}

.progress-container{
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    vertical-align: middle;
    overflow: hidden;
}

.vital-text {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25em;
}


@media only screen and (max-width: 1024px) {
    .vital-text {
        font-weight : 400;
        font-size: 0.8em;
    }

    .progress-container {
        width: 30px;
    }
}
