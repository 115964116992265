
.gamepad-speed {
    position: absolute;
    bottom: 86px;
    left : 50%;
    width: 160px !important;
    transform: translate(-50%, -50%);
}

.joystick-container{
    position: absolute;
    height: 250px;
    width: 250px;
}

.joystick-container.left{
    left : 20px;
    bottom : 30px;
}

.joystick-container.right{
    right : 20px;
    bottom : 30px;
}