/* RecordingWidget.css */

.recording-control-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.recording-control-box button {
    margin: 5px 0;
}

.current-stop-display {
    font-size: 1.2em;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
}

.asset-input-box {
    display: flex;
    gap: 10px;
    align-items: center;
}

.asset-input-box input {
    flex-grow: 1;
}

.asset-input-box button {
    flex-shrink: 0;
}

/* Adjustments for different screen sizes */
@media (max-width: 1024px) {
    .recording-control-box {
        flex-direction: column;
    }

    .asset-input-box {
        flex-direction: column;
    }

    .asset-input-box input,
    .asset-input-box button {
        width: 100%;
    }
}
