.mission-scheduler {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
  }
  .schedule-grid.disabled {
    opacity: 0.5; /* Reduce opacity to make it look disabled */
    pointer-events: none; /* Prevent interaction */
  }
  
  .current-time {
    font-size: 16px;
    margin-bottom: 20px;
    color: black;
  }
  
  .schedule-list {
    margin-bottom: 20px;
    color: black;
  }
  
  .schedule-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .schedule-list li {
    margin-bottom: 10px;
    color: black;
  }
  
  .new-schedule {
    margin-top: 20px;
    color: black;
  }
  
  .new-schedule input {
    margin-right: 10px;
  }
  
  .days-of-week {
    display: flex;
    flex-wrap: wrap;
  }
  
  .days-of-week label {
    margin-right: 10px;
    margin-top: 10px;
  }
  
  button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .schedule-grid {
    display: grid;
    grid-template-columns: auto repeat(7, 1fr);
    grid-template-rows: auto repeat(24, 1fr);
    gap: 1px;
    width: 100%;
    border: 1px solid #ccc;
  }
  
  .time-slot {
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
    height: 40px;
    border: 1px solid #ccc;
    background-color: #f4f4f4;
  }
  
  .day-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    font-weight: bold;
  }
  
  .schedule-cell {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    height: 40px;
    border: 1px solid #ccc;
  }
  
  .mission-block {
    display: inline-block;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    /* background-color: #e3f2fd; */
    /* padding: 5px; */
    /* border-radius: 5px; */
  }
  
  .mission-block button {
    margin-top: 5px;
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .mission-block button:hover {
    background-color: #d32f2f;
  }
  