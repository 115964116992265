
.widget-card{
    position: absolute !important;
    height : 25vh !important;
    width : 16vw !important;
}
  
.widget-interaction-button{
    opacity: 0.75;
    color: #E1E1E1;
}
  
.widget-interaction-button:hover{
    opacity: 1 !important;
    cursor: unset;
}


.widget-bottom-right{
    top : unset;
    bottom : 20px;
    right : 20px;
    left : unset;
}

.widget-bottom-left{
    top : unset;
    bottom : 20px;
    right : unset;
    left : 20px;
}

.widget-top-right{
    top : 20px;
    bottom : unset;
    left : unset;
    right : 20px; 
}


@media only screen and (max-width: 1024px) {
    .widget-bottom-right {
        height : 10rem;
        width: 10rem;
    }
}