/* WebrtcComponent.css */

.fullscreen-video {
  position: relative; /* Ensure positioning context */
  display: flex; 
  justify-content: center; 
  align-items: center; 
  width: 100%; 
  height: 100%; 
  overflow: hidden; /* Prevent unwanted scrollbars */
}

.fullscreen-video video {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Maintain aspect ratio while fitting inside the container */
}
