button {
    padding: 10px 20px;
    margin: 5px;
    border: 2px solid #007bff;
    background-color: #fff;
    color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  button:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  button.selected {
    background-color: #007bff;
    color: #fff;
    border-color: #0056b3;
  }
  