:root{
    --CONNECTION_STATUS_HEIGHT : 35px;
    --CONNECTION_STATUS_WIDTH : 35PX;
}

/*
* Main Container
*/

    .status-container-side{
        left: 30px;
        top: 50%;
        height: 600px;
        width: 55px;
        display: inline-block;
        vertical-align: middle;
        position: fixed;
        transform: translateY(-50%);
    }

    .status-container-bottom-centre{
        bottom: 30px;
        left: 50%;
        height: 55px;
        width: 50%;
        display: inline-block;
        position: fixed;
        transform: translate(-50%);
    }


    .rounded-2-5{
        border-radius: 2.25rem !important;
    }

    .vital-type{
        opacity: 1;
    }

    .vital-progress-text{
        font-weight: 500;
    }

/*
 * Robot Connection slider 
 */

    .robot-status-slider{
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    /* Status slide bar */
    .robot-status-slider input[type="checkbox"]{
        display: none;
    }

    /*Button is :CHECKED*/

    .robot-status-slider input[type="checkbox"]:checked ~ div {
        background: var(--SUCCESS);
        box-shadow: 0 0 2px var(--SUCCESS);
    }

    .robot-status-slider input[type="checkbox"]:checked ~ div label {
        left: 40px;
        transform: rotate(360deg);
    }


    /*shared*/

    .robot-status-slider .status-label-container,
    .robot-status-slider label {
        border-radius: 50px;
    }


    .status-label-container-new{
        height: 45px;
        width: 45px;
        background: var(--ERROR);
        position: relative;
        box-shadow: 0 0 2px var(--ERROR);
        z-index: 2;
        border-radius: 50%;
    }


    /*'un':checked state*/

    .robot-status-slider .status-label-container{
        height: 45px;
        width: 80px;
        background: var(--ERROR);
        position: relative;
        box-shadow: 0 0 2px var(--ERROR);
    }

    .robot-status-slider label {
        height: var(--CONNECTION_STATUS_HEIGHT);
        width: var(--CONNECTION_STATUS_WIDTH);
        background: #E1E1E1;
        position: absolute;
        top: 5px;
        left: 5px;
    }


    .robot-status-wifi{
        height : 90%;
        width : 80%;
        position : absolute;
        top : 0px;
        left: 5px;
    }
    .robot-status-wifi-background{
        height : 90%;
        width : 80%;
        position : absolute;
        top : 0px;
        left: 5px;
        color : #E1E1E1;
        opacity: 0.4;
    }

    .wifi-meter{
        color : white;
        height: var(--CONNECTION_STATUS_HEIGHT);
        width: var(--CONNECTION_STATUS_WIDTH);
        top : 5px;
        left : 3px;
        position: absolute;
    }

    .connection-spinner{
        position : absolute;
        top : calc(50% - 5px);
        right : 5px;
    }

    .connection-spinner .spinner-grow {
        width : 0.5rem !important;
        height : 0.5rem !important;
    }

    @-o-keyframes fadeOnlineColourIn {
        0% { opacity: 0;}
        50% { opacity: .5;}
        100% { opacity: 1;}
    }

    @keyframes fadeOnlineColourIn {
        0% { opacity: 0;}
        50% { opacity: .5;}
        100% { opacity: 1;}
    }

    @-o-keyframes fadeOfflineColourIn {
        0% { opacity: 0;}
        50% { opacity: .5;}
        100% { opacity: 1;}
    }

    @keyframes fadeOfflineColourIn {
        0% { opacity: 0;}
        50% { opacity: .5;}
        100% { opacity: 1;}
    }

    .fadeOnline {
        -o-animation: fadeOnlineColourIn 1s linear;
        animation : fadeOnlineColourIn 1s linear;
    }

    .fadeOffline {
        -o-animation: fadeOfflineColourIn 1s linear;
        animation : fadeOfflineColourIn 1s linear;
    }


/* Transitions */
    .robot-status-slider,
    .robot-status-slider label,
    .status-label-container,
    .robot-status-wifi,
    .wifi-meter,
    .robot-status-wifi-background,
    .robot-status-slider input[type="checkbox"],
    .wifi-spinner
    {
        transition: 400ms all ease-in-out 50ms;
        box-sizing: border-box;
        backface-visibility: hidden;
    }
  
  

/* Media Queries */
@media only screen and (max-width: 1024px) {
    .status-container-bottom-centre{
        width : 80%;
        height : 30px;
        top : 10px;
        bottom : unset;
    }

    .robot-status-slider .status-label-container{
        height : 26px;
        width : 46px;
    }

    .robot-status-slider input[type="checkbox"]:checked ~ div label {
        left : 23px;
    }

    .robot-status-slider label {
        height : 20px;
        width : 20px;
        top : 3px;
    }

    .wifi-meter {
        height : 20px;
        width : 20px;
        top : 5px;
        left : 0px;
    }

    .fa-lg {
        font-size: 0.9em !important;
    }

    .svg-inline--fa.fa-lg {
        vertical-align: 0.05em !important;
    }

    .wifi-spinner {
        top: 1px;
        right : -7px;
    }

    .spinner-border-sm {
        width : 0.7rem;
        height : 0.7rem;
    }

    .connection-spinner{
        top: calc(50% - 2px);
    }

    .connection-spinner .spinner-grow {
        height: 0.1rem !important;
        width: 0.1rem !important;
    }
}