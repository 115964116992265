/**
 * *************** Video *******************
 */

/* .video-container{
  position: relative;
  z-index: 0;
} */

.video-container video {
    /* z-index: 1;
    min-width: 100%;
    min-height: 100%;
  
    width: auto;
    height: auto;
  
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); */
    /* object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0; */
    
  }
  
  .video-background{
    /* position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden; */
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .video-component-overlay{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    background-color: transparent;
  }

  .display-4{
      font-size: 1.2rem !important;
  }


  #main-video{
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .centre-video-text {
    position: absolute;
    top : 50%;
    left : 50%;
    transform : translate(-50%, -50%);
  }

  .video-connection-message {

  }