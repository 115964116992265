/* ToggleSwitchWidget.module.css */

.toggleSwitchContainer {
  display: inline-block; /* Change to inline-block to make it take up only as much width as necessary */
  position: relative; /* Ensure positioning context for absolute positioning */
  padding: 8px;
  width: fit-content;
}

.labelInput {
  position: relative;
  top: 50%; /* Position at the vertical center of the toggle container */
  /* transform: translateY(-50%); Adjust to vertically center */
  /* right: 70px; Adjust based on the width of the switch + space */
  width: 130px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white; /* Ensure label is over toggle */
  z-index: 1; /* Ensure label is above the toggle */
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 4px; /* Rounded edges for the track */
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2); /* Ridges effect for the track */
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 4px; /* Rounded edges for the slider knob */
  box-shadow:
    inset 0 0 2px rgba(0, 0, 0, 0.2), /* Ridges effect for the knob */
    0 2px 2px rgba(0, 0, 0, 0.2); /* Shadow for textured appearance */
}

input:checked + .slider {
  background-color: #4caf50; /* Change color to green when switched on */
}

input:focus + .slider {
  box-shadow: 0 0 1px #4caf50; /* Change shadow color when focused */
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.square {
  border-radius: 4px; /* Adjust this value for roundness */
}
