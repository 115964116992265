.emergency-shutdown-container {
    display: flex;
    /* gap: 10px; */
    margin: 2px;
  }
  
  .arm-button {
    background-color: #f0ad4e;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  .shutdown-button {
    background-color: #d9534f;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  