.modal-content {
    display: flex;
    flex-direction: column;
    max-height: 90vh; /* Ensure it fits within the viewport */
  }

  
  .image-container {
    padding:10px;
    height: 100%;
    overflow-y: auto; /* Add scrollbar when content overflows */
  }

  .image-wrapper {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-wrapper img {
    width: auto;
    height: auto;

  }
  
  .accordion-container {
    flex: 1;
    overflow-y: auto; /* Allow scrolling if content is too long */
    padding-right: 10px; /* Add some padding to avoid content hiding under scrollbar */
  }
  
  .accordion {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .accordion-summary {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    background-color: #f7f7f7;
    color: #333; /* Darker text color */
    font-weight: bold;
  }
  
  .accordion-details {
    padding: 10px;
    background-color: #fff;
    color: #333; /* Darker text color */
  }
  
  .asset-label {
    display: block;
    margin-bottom: 5px;
    color: #333; /* Darker text color */
  }
  
  .confirm-button-container {
    background-color: white; /* To avoid overlap with the content */
    padding: 10px 0;
    text-align: center;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  